import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Layout from "src/layout"

const Container = styled.div`
  max-width: 1000px;
  margin: auto;
  padding: 8px;
`
const P = styled.p`
  line-height: 1.4em;
`
const LeftParagraph = styled(P)`
  text-align: center;

  &:not(:last-child):after {
    content: "***";
    display: block;
    margin: 20px 0;
    letter-spacing: 5px;
  }
`
const ColContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
`

const Col = styled.div<{ $width?: number }>`
  flex: 1;
  flex-basis: 30%;
  ${({ $width }) => ($width ? `max-width: ${$width};` : ``)}
  min-width: 250px;
  padding: 10px;
  box-sizing: border-box;
`

const Center = styled.div`
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  flex-basis: 40%;
`

const RightParagraph = styled(P)`
  text-align: justify;
`
const CenterTitle = styled.p`
  font-size: 25px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  margin: 50px 0;
`
const CenterParagraph = styled.p<{ $align: string; $margin: number }>`
  font-family: "Times New Roman", Times, serif;
  text-align: ${({ $align }) => $align};
  font-weight: bold;
  margin: ${({ $margin }) => $margin}px 0;
`
const Legend = styled.div`
  font-style: italic;
  color: #717171;
  font-size: 14px;

  a {
    color: #717171;
  }
`

const Presentation = () => {
  // const { hinterland, atelier } = data

  return (
    <Layout title="Presentation" active={1}>
      <Container>
        <StaticImage src="../assets/hinterland.jpeg" alt="Hinterland" />
        <ColContainer>
          <Col>
            <LeftParagraph>
              Hinterland c’est l’arrière-pays. C’est aussi une zone saturée, un monde perdu, à l’arrière d’un port ou
              d’une jungle, rendu désertique et artificiel. C’est la zone où sont rassemblées les marchandises.
            </LeftParagraph>
            <LeftParagraph>
              Notre collectif se compose de danseurs, musiciens, cinéastes, plasticiens et philosophes qui comme après
              un naufrage ont rassemblé leurs outils dans cet arrière- monde qu’est l’atelier.
            </LeftParagraph>
          </Col>
          <Center>
            <CenterTitle>[intε ʀlɑ ̃:d]!</CenterTitle>
            <CenterParagraph $align="left" $margin={10}>
              init. non aspirée. Empr. de
            </CenterParagraph>
            <CenterParagraph $align="right" $margin={10}>
              l’all. Hinterland « arrière-pays »
            </CenterParagraph>
            <br />
            <br />
            <br />
            <CenterParagraph $align="right" $margin={10}>
              composé de hinter « derrière »
            </CenterParagraph>

            <CenterParagraph $align="right" $margin={10}>
              et de Land « terre, pays
            </CenterParagraph>
          </Center>
          <Col>
            <RightParagraph>
              L’arrière-fond d’archives constitue nos images du futur. Nous y invitons quiconque porte en lui le désir
              d’y consigner quelques gestes en cachette.
            </RightParagraph>
            <RightParagraph>
              C’est depuis l’atelier que l’Hinterland se prépare au dévoilement de ses actions qui peuvent prendre la
              forme de spectacles, de performances, d’exposition, de revues, de séances, de concerts, de banquets,
              d’expéditions, ou simplement d’un moment de vie comme il arrive qu’elle nous échappe.
            </RightParagraph>
          </Col>
        </ColContainer>
        <div>
          <StaticImage src="../assets/atelier.jpg" alt="Nicolas Fayol, l'atelier de Sylvain Gaudenzi, 2018" />
          <Legend>
            <Link to="/Nicolas-Fayol">Nicolas Fayol</Link>, l&apos;atelier de{" "}
            <Link to="/Sylvain-Gaudenzi">Sylvain Gaudenzi</Link>, 2018
          </Legend>
        </div>
      </Container>
    </Layout>
  )
}

// export const pageQuery = graphql`
//   query {
//     hinterland: file(relativePath: { eq: "hinterland.jpeg" }) {
//       id
//       childImageSharp {
//         gatsbyImageData(width: 1200, placeholder: BLURRED)
//       }
//     }
//     atelier: file(relativePath: { eq: "atelier.jpg" }) {
//       id
//       childImageSharp {
//         gatsbyImageData(width: 1200, placeholder: BLURRED)
//       }
//     }
//   }
// `

export default Presentation
